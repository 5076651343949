<template>
  <teleport to="head">
    <title>Наши работы по укладке тротуарной плитки</title>
  </teleport>
  <main class="main page__view">
    <h2 class="header-text">Наши работы по укладке тротуарной плитки</h2>
    <div class="works">
      <div
        class="work"
        @click="showLightBox(idx)"
        v-for="(workImage, idx) of worksImages"
        :key="idx"
      >
        <img
          class="work__image"
          alt="Наши работы по укладке тротуарной плитки"
          :src="workImage"
        />
        <div class="work__overlay">
          <fontAwesomeIcon class="work__overlay__icon" icon="search-plus" />
        </div>
      </div>
    </div>
    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="lightbox.visible"
      :imgs="worksImages"
      :index="lightbox.index"
      @hide="handleLightBoxHide"
    ></vue-easy-lightbox>
  </main>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
export default {
  data() {
    return {
      lightbox: {
        visible: false,
        index: 0, // default: 0
      },
      worksImages: [
        '/images/our-works/photo_2021-03-03_19-29-04.jpg',
        '/images/our-works/photo_2021-03-03_19-29-17.jpg',
        '/images/our-works/photo_2021-03-03_19-29-22.jpg',
        '/images/our-works/photo_2021-03-03_19-29-32.jpg',
        '/images/our-works/photo_2021-03-03_19-29-35.jpg',
        '/images/our-works/photo_2021-03-03_19-29-38.jpg',
        '/images/our-works/photo_2021-03-03_19-29-40.jpg',
        '/images/our-works/photo_2021-03-03_19-29-42.jpg',
        '/images/our-works/photo_2021-03-03_19-29-45.jpg',
        '/images/our-works/photo_2021-03-03_19-29-52.jpg',
        '/images/our-works/photo_2021-03-03_19-29-55.jpg',
        '/images/our-works/photo_2021-03-03_19-29-57.jpg',
      ],
    };
  },
  computed: {
    imgs() {
      return this.works.map((work) => work.image);
    },
  },
  methods: {
    showLightBox(index) {
      this.lightbox.index = index;
      this.lightbox.visible = true;
    },
    handleLightBoxHide() {
      this.lightbox.visible = false;
    },
  },

  components: {
    VueEasyLightbox,
  },
};
</script>

<style scoped>
.main {
  padding: 20px;
}
.header-text {
  color: var(--primary-color);
}

/* Works */
.works {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-gap: 20px;
}

@media (max-width: 880px) {
  .works {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 650px) {
  .works {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Work */
.work {
  position: relative;
  cursor: pointer;
  height: 200px;
}

/* Work Image */
.work__image {
  width: 100%;
  height: 100%;
}
.work__overlay {
  display: none;
  position: absolute;

  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .work__overlay {
    display: flex;
  }
}

.work:hover > .work__overlay {
  display: flex;
}

.work__overlay__icon {
  width: 35%;
}
</style>
